




















import vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

@Component
export default class Loading extends vue {
    @Prop() isDone!: any;
    percent: number = 0;
    timer: any;
    t2: any;
    counter = 0;
    @Emit('isDone')
    done() {}

    mounted() {
        this.timer = setInterval(this.onTick, 10);
        this.t2 = setInterval(() => this.counter++, 1);
    }
    onTick() {
        this.percent++;
        if (this.percent === 30) {
            clearInterval(this.timer);
            this.timer = setInterval(this.onTick, 500);
        }
        if (this.percent === 34) {
            clearInterval(this.timer);
            this.timer = setInterval(this.onTick, 100);
        }
        if (this.percent === 42) {
            clearInterval(this.timer);
            this.timer = setInterval(this.onTick, 5);
        }
        if (this.percent === 99) {
            clearInterval(this.timer);
            this.timer = setInterval(this.onTick, 1000);
        }
        if (this.percent === 100) {
            clearInterval(this.timer);
            clearInterval(this.t2);
            this.timer = null;
            this.t2 = null;
            setTimeout(() => this.done(), 500);
        }
    }
}
